import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Router from 'next/router';
import {
  actionTypes,
  loadUserInfo,
  loadUserInfoSucess,
  updateUserInfo,
  userUpdateAcceptPolicySuccess,
  updateShowTutorialAggregationSuccess,
  loadUserAssetInfo
} from '@src/actions/userActions';
import { appAxios, setHeaderAppAxios } from '@src/api/AppAxios';
import { getHeaders } from '@src/utils/headersUtil';
import { getPathHasLastSlash, saveTokenExpire, validURL } from '@src/utils/functionUtils';

import { setLoading, setToggleAuthenticating, setErrorMessage, actionTypes as actionCommonTypes } from '@src/actions/commonActions';
import { UserApiServices } from '@src/api/UserApiServices';
import { userInfoSeletor } from '@src/selectors/userSeletor';
import errorHandler, { getErrorResponseAxios } from '@src/utils/errorHandler';
import { trackAnalyticsAAUser, trackAnalyticsBundle } from '@src/analyticsEvents/analyticsAAUserEvents';
import { logPageView } from '@src/utils/analytics';
import { MODAL_NAME, pathConstant } from '@src/utils/appContanst';
import { show } from 'redux-modal';
import { IAppState } from '@src/interfaces/IAppState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loginSaga(action: any) {
  try {
    const vtkt = Cookies.get('VTKT') || Cookies.get('vtkt') || '';
    if (!vtkt) {
      throw new Error('VTKT not found');
    }
    const bodyData = new FormData();
    bodyData.append('vtkt', vtkt);
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*'
      }
    };
    const { data: { data: { token } } }: AxiosResponse = yield appAxios.post('/auth/authorize', bodyData, config);
    sessionStorage && sessionStorage.setItem('token', token);
    saveTokenExpire(token);
    setHeaderAppAxios(token);
    yield put(loadUserInfo(false));
    yield put(loadUserAssetInfo());
    const { callback } = action.data;
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    const errorAuth: AxiosError = error;
    const { message, code } = getErrorResponseAxios(errorAuth);
    yield put(setToggleAuthenticating({
      isAuthenSuccess: false,
      isAuthenticating: false,
      messageAuthentication: ''
    }));
    yield put(setErrorMessage({ title: 'Error Authorization', message, statusCode: code }));
    // If error 503 by vtkt got expired => ignore it
    if (code !== 503 && !message.includes('Service Unavailable')) {
      errorHandler(error);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * loadUserInfoSaga(action: any) {
  try {
    const { useLoadingModal } = action;
    if (useLoadingModal) {
      yield put(setLoading(true));
    }
    const { data }: AxiosResponse = yield call(UserApiServices.getUserProfile);
    yield put(loadUserInfoSucess(data));
    yield put(updateUserInfo(data));
    if (useLoadingModal) {
      yield put(setLoading(false));
    }
    yield put(setToggleAuthenticating({
      isAuthenSuccess: true,
      isAuthenticating: false,
      messageAuthentication: ''
    }));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setToggleAuthenticating({
      isAuthenSuccess: false,
      isAuthenticating: false,
      messageAuthentication: ''
    }));
    errorHandler(error);
  }
}

function * updateUserSaga() {
  const { nick_name } = yield select(userInfoSeletor);
  try {
    const params = {
      nick_name
    };

    yield put(setLoading(true));
    const headers = yield getHeaders();
    const { data: { status } }: AxiosResponse = yield appAxios.post('/user/info', params, {
      headers: headers
    });
    if (status >= 200 && status < 300) {
      yield put(setLoading(false));
      return;
    }
  } catch (error) {
    errorHandler(error);
  }
}
function * loadAssetInfoSaga() {
  try {
    const { data }: AxiosResponse = yield call(UserApiServices.getUserAssetInfo);
    const { balanceState } = yield select((state: IAppState) => state);
    const {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts
    } = balanceState;
    const setUserCondition = {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts
    };
    yield put(loadUserInfo());
    trackAnalyticsBundle(setUserCondition);
    trackAnalyticsAAUser(data);
  } catch (err) {
    errorHandler(err);
  }
  const currentPath = getPathHasLastSlash(window.location.pathname);
  const disableTrackingKarte = ['/asset/au_pay/'].includes(currentPath);

  logPageView(disableTrackingKarte);
}

function * updateShowTutorialAggregationSaga() {
  try {
    yield put(setLoading(true));
    yield call(UserApiServices.updateUserInfo, { is_hide_aggregation_tutorial: true });
    yield put(updateShowTutorialAggregationSuccess());
    yield put(setLoading(false));
    Router.push('/aggregation/');
  } catch (error) {
    errorHandler(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * userUpdateAcceptPolicySaga(action: any) {
  let { redirectLink, agree } = action;
  agree = agree || { is_accept_policy: true };
  redirectLink = redirectLink || pathConstant.preAuth;

  try {
    const { status, data }: AxiosResponse = yield call(UserApiServices.updateUserInfo, agree);
    if (status >= 200 && status < 300) {
      if (data && !data.is_success) {
        yield put(show(MODAL_NAME.DELETED_STOCK_USER_UNUSED));
      } else {
        yield put(userUpdateAcceptPolicySuccess(agree));
        if (validURL(redirectLink)) {
          window.location.href = redirectLink;
        } else {
          Router.push(redirectLink);
        }
      }
    } else {
      Router.push(pathConstant.topPath);
    }
  } catch (error) {
    Router.push(pathConstant.topPath);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * postAnalyticDataSaga(action: any) {
  const { anlyticData } = action;
  try {
    yield call(UserApiServices.postDataAnlytic, anlyticData);
  } catch (error) {
    errorHandler(error);
  }
}

function * watchPostAnalyticDataSaga() {
  yield takeLatest(actionCommonTypes.SAVE_DATA_ANALYTIC, postAnalyticDataSaga);
}

function * watchUpdateUser() {
  yield takeLatest(actionTypes.UPDATE_USER, updateUserSaga);
}

function * watchUpdateShowTutorialAggregation() {
  yield takeLatest(actionTypes.UPDATE_SHOW_TUTORIAL_AGGREGATION, updateShowTutorialAggregationSaga);
}

function * watchLogin() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
}

function * watchLoadUserInfo() {
  yield takeLatest(actionTypes.LOAD_USER_INFO, loadUserInfoSaga);
}

function * watchUserUpdateAcceptPolicySaga() {
  yield takeLatest(actionTypes.USER_UPDATE_ACCEPT_POLICY, userUpdateAcceptPolicySaga);
}
function * watchLoadAssetInfo() {
  yield takeLatest(actionTypes.LOAD_USER_ASSET_INFO, loadAssetInfoSaga);
}

export function * userSaga() {
  yield all([
    watchLogin(),
    watchLoadUserInfo(),
    watchUpdateUser(),
    watchUserUpdateAcceptPolicySaga(),
    watchUpdateShowTutorialAggregation(),
    watchPostAnalyticDataSaga(),
    watchLoadAssetInfo()
  ]);
}

import { all, takeLatest, put, call, select, fork } from 'redux-saga/effects';
import queryString from 'query-string';
import {
  actionTypes,
  getBalanceDetailSuccess,
  getTransactionsChartSuccess,
  fetchMoreTransactionsChartSuccess,
  getTransactionsChart,
  updateAccShowCampaignText,
  getAuAndStockBalanceInfoSuccess,
  setAuLoading,
  getBundleSettingsSuccess,
  setStockLoading
} from '@src/actions/balanceActions';
import { setLoading } from '@src/actions/commonActions';
import { BalanceApiServices } from '@src/api/BalanceApiServices';
import errorHandler from '@src/utils/errorHandler';
import { getAccountType, getAuObj, getBundleMsg, getPathHasLastSlash, groupStockProducts, trackingDisplayForMsgOfNewAccounts, trackingDisplayKantan } from '@src/utils/functionUtils';
import { handleEnableLoadingModal, handleDisableLoadingModal } from './commonSaga';
import { AccountType, MAINTENANCE_STATUS, FINANCIAL_BUNDLE, PATH_ROUTE } from '@src/utils/appContanst';
import { loadListHistorySuccess } from '@src/actions/historyActions';
import { HistoryApiServices } from '@src/api/HistoryApiServices';
import _find from 'lodash.find';
import _filter from 'lodash.filter';
import { AxiosResponse } from 'axios';
import { balanceAccountSelector, balanceStateSelector, isAuLoadingSelector, isStockLoadingSelector } from '@src/selectors/balanceSelector';
import { initialPointInfo, initialUserCondition, inittialLayoutError, intitialKantanInfo } from '@src/utils/initialData';
import { IAccount } from '@src/interfaces/IBalanceState';
import { IAppState } from '@src/interfaces/IAppState';
import router from 'next/router';
import { handleSendEventToKarteForAuAcc } from '@src/balance/components/functions/analyticsKarte';
import { handleTrackingCampaignMsgGAForBundle, handleTrackingGAInDetailForBundle } from '@src/balance/components/functions/analyticsGA';

function * runAfterCallAuAndStockSaga(useLoadingModal: boolean) {
  const { balanceState } = yield select((state: IAppState) => state);
  yield call(handleRandomCampaignTextAuAcc);
  if (router.pathname.indexOf('/asset') > -1) {
    yield trackingDisplayKantan(balanceState);
    const { auAccShowcampaignText } = yield select(balanceStateSelector);

    const {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      bundle
    } = balanceState;

    const setUserCondition = {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts
    };

    handleSendEventToKarteForAuAcc(balanceState, auAccShowcampaignText, setUserCondition, bundle);
  }
  yield handleDisableLoadingModal(useLoadingModal);
}

export function * getStockSaga(useLoadingModal: boolean) {
  try {
    yield put(setStockLoading(true));
    const { data, maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE } = yield call(BalanceApiServices.getAuStockBalance);
    yield put(getAuAndStockBalanceInfoSuccess({ ...data, maintenance_status }, false));
    yield put(setStockLoading(false));

    const isAuLoading:boolean = yield select(isAuLoadingSelector);

    if (sessionStorage) {
      sessionStorage.setItem('auStock', JSON.stringify(data.stock));
    }
    // when stock is done check if au done or not
    if (!isAuLoading) {
      yield runAfterCallAuAndStockSaga(useLoadingModal);
    }
  } catch (error) {
    errorHandler(error);
  }
}

export function * getAuSaga(useLoadingModal: boolean) {
  try {
    yield put(setAuLoading(true));
    const { data, maintenance_status = MAINTENANCE_STATUS.NO_MAINTENANCE } = yield call(BalanceApiServices.getAuBalance);
    yield put(getAuAndStockBalanceInfoSuccess({ ...data, maintenance_status }, true));
    yield put(setAuLoading(false));

    const isStockLoading:boolean = yield select(isStockLoadingSelector);

    if (sessionStorage) {
      sessionStorage.setItem('auJbank', JSON.stringify(data.bank));
    }

    // when au is done check if stock done or not
    if (!isStockLoading) {
      yield runAfterCallAuAndStockSaga(useLoadingModal);
    }
  } catch (error) {
    errorHandler(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function * getAuAndStockBalanceInfoSaga(action: any) {
  try {
    const { useLoadingModal = true } = action;
    yield handleEnableLoadingModal(useLoadingModal);
    yield fork(getAuSaga, useLoadingModal);
    yield fork(getStockSaga, useLoadingModal);
  } catch (error) {
    errorHandler(error);
  }
}

function * handleTrackingVisibleCampaignMsg(
  auAccShowcampaignText: string) {
  const isAuAccKantan = auAccShowcampaignText === AccountType.au_kantan_kessai;

  // handle for msg on Top
  if (!isAuAccKantan) {
    yield trackingDisplayForMsgOfNewAccounts(auAccShowcampaignText);
  }
}

function * handleRandomCampaignTextAuAcc() {
  try {
    const { e_money, bank, stock } = yield select(balanceStateSelector);

    const allServices = e_money.accounts.concat(bank.accounts).concat(stock.accounts);
    const { balanceState } = yield select((state: IAppState) => state);
    // when bundle is true, and financialBundlePlanMember != -1, add au Pay in random logic
    const auAccsShowCampaignText = [AccountType.au_jbank, AccountType.au_stock];
    const {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      bundle,
      maintenance_status
    } = balanceState;
    const setUserCondition = {
      financialBundlePlanMember,
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts
    };
    const { message_settings, admin_settings } = bundle;
    const isShowingBundle =
      (financialBundlePlanMember !== FINANCIAL_BUNDLE.UNKNOWN) &&
      admin_settings.show_financial_packages &&
      message_settings.show_financial_packages;

    const auAccsHaveNotLinked = _filter(
      allServices,
      (item: IAccount) => {
        const auObj = !item.id || item.is_error
          ? getAuObj(item.id, item.data_source, item.status, undefined, intitialKantanInfo)
          : inittialLayoutError;
        const accType = getAccountType(item.data_source);

        const isAuMaintenance = MAINTENANCE_STATUS.SWALLOW_MAINTENANCE === maintenance_status;
        const isNoLinkAuAcc = auAccsShowCampaignText.includes(accType) &&
          !!auObj &&
          !!auObj.campaignText &&
          !isAuMaintenance &&
          auObj.theme === 'new_acc';
        // incase AuStock is new Acc will display like Au Acc
        const isNoLinkAuStock = accType === AccountType.au_stock && !item.id;

        return (isNoLinkAuAcc || isNoLinkAuStock);
      });

    const auAccsHaveBundleMsg = _filter(
      allServices,
      (item: IAccount) => {
        const accountStatus = !item.id ? 'unlinked' : '';
        const accType = getAccountType(item.data_source);
        const isAuPay = AccountType.au_prepaid === accType;
        if (isShowingBundle && !isAuPay) {
          const bundleMsg =
            bundle &&
            getBundleMsg(
              setUserCondition || initialUserCondition,
              bundle,
              item.data_source,
              stock,
              accountStatus);
          return bundleMsg;
        }
      });

    const arrayUnique = auAccsHaveNotLinked.filter((item : IAccount) => !auAccsHaveBundleMsg.includes(item));

    const accsLength = auAccsHaveNotLinked.length;
    const auAcc = accsLength ? auAccsHaveNotLinked[Math.floor(Math.random() * accsLength)] : null;
    const auAccShowcampaignText = auAcc ? getAccountType(auAcc.data_source) : 'ALL';

    // at Asset top, show random msg for au accs have not linked yet.
    yield put(updateAccShowCampaignText(auAccShowcampaignText));
    const fullPathname = getPathHasLastSlash(router.pathname);
    if (fullPathname === PATH_ROUTE.top) {
      if (isShowingBundle) {
        handleTrackingCampaignMsgGAForBundle(
          balanceState,
          auAccShowcampaignText,
          setUserCondition,
          bundle
        );
        arrayUnique.forEach((item: IAccount) => {
          if (auAccShowcampaignText === getAccountType(item.data_source)) {
            trackingDisplayForMsgOfNewAccounts(getAccountType(item.data_source));
          }
        });
      } else {
        yield handleTrackingVisibleCampaignMsg(auAccShowcampaignText);
      }
    }
  } catch (error) {
    errorHandler(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * getBalanceDetailSaga(action: any) {
  const { id, datetime, chartFrom, chartTo } = action;
  const { query } = queryString.parseUrl(window.location.search);
  const { balanceState } = yield select((state: IAppState) => state);
  const { financialBundlePlanMember, bundle } = balanceState;

  handleTrackingGAInDetailForBundle(financialBundlePlanMember, query.dataSource, bundle);
  try {
    yield put(setLoading(true));
    yield call(
      handleGetBalanceDetailData,
      id,
      +(query.dataSource),
      datetime,
      chartFrom,
      chartTo
    );
    yield put(setLoading(false));
  } catch (error) {
    errorHandler(error);
  };
}

function * handleGetBalanceDetailData(
  id: string,
  dataSource:number,
  datetime: string,
  chartFrom: string,
  chartTo: string
) {
  const accountType = getAccountType(+dataSource);
  const isAuPonta = accountType === AccountType.au_ponta;

  try {
    yield put(setLoading(true));
    const APIs = [call(BalanceApiServices.getBalanceDetail, id, datetime)];
    // fomular excute value of point difference with other acc
    isAuPonta && APIs.push(call(HistoryApiServices.getHistoryTransactionAuPonta, { account_id: id, time: datetime }));
    const [asset, transation]: Array<AxiosResponse> = yield all(APIs);
    const assetData = asset.data;
    const maintenanceStatus = asset;
    const { main_account_id } = assetData.point_info || { main_account_id: '' };
    // need to call API to drawn chart for active time
    const activeChart: AxiosResponse = yield call(BalanceApiServices.getTransactionsChart, id, datetime, datetime, dataSource, main_account_id);
    assetData.transactions = isAuPonta ? transation.data.transactions : assetData.transactions;
    const dataTransaction = assetData.transactions;
    const stocks = assetData.stocks || [];
    const activeObj = _find(activeChart.data.values || [], (item: {time: string, value: number}) => item.time === datetime);
    const activeChartData = activeObj ? activeObj.value : 0;

    if (chartFrom && chartTo) {
      yield put(getTransactionsChart(id, chartFrom, chartTo, +dataSource, main_account_id));
    }
    yield put(loadListHistorySuccess({
      transactions: dataTransaction || [],
      products: groupStockProducts(stocks),
      stocks
    }));
    // Make the changed data occur in the end => Animation of chart will not stop.
    yield put(getBalanceDetailSuccess({
      ...assetData,
      maintenance_status: maintenanceStatus,
      activeChartData
    }, datetime));
  } catch (error) {
    errorHandler(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * getTransactionsChartSaga(action: any) {
  try {
    const { account_ids, from, to, dataSource, mainAccountID } = action;

    if (from <= to) {
      const { data } = yield call(
        BalanceApiServices.getTransactionsChart,
        account_ids,
        from,
        to,
        dataSource,
        mainAccountID
      );
      yield put(getTransactionsChartSuccess(data));
    }
  } catch (err) {
    errorHandler(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * fetchMoreTransactionsChartSaga(action: any) {
  try {
    const { query } = queryString.parseUrl(window.location.search);
    const { dataSource = '0' } = query;
    const { account_ids, from, to, typeFetchChart } = action;
    const { point_info = initialPointInfo } = yield select(balanceAccountSelector);

    if (from <= to) {
      const { data } = yield call(
        BalanceApiServices.getTransactionsChart,
        account_ids,
        from,
        to,
        dataSource,
        point_info.main_account_id
      );
      yield put(fetchMoreTransactionsChartSuccess(data, typeFetchChart));
    }
  } catch (err) {
    errorHandler(err);
  }
}

function * getBundleInfoSaga() {
  try {
    yield put(setLoading(true));
    const { data } = yield call(BalanceApiServices.getBundleInfo);

    yield put(getBundleSettingsSuccess(data));
    yield put(setLoading(false));
  } catch (error) {
    errorHandler(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * sendBundleSettingsSaga(action: any) {
  try {
    yield put(setLoading(true));

    const { message_settings } = action;
    yield call(
      BalanceApiServices.updateBundleSettings, message_settings.show_financial_packages
    );
    yield put(setLoading(false));
  } catch (error) {
    errorHandler(error);
  }
}

function * watchGetBundleInfo() {
  yield takeLatest(actionTypes.GET_BUNDLE_INFO, getBundleInfoSaga);
}

function * watchUpdateBundleSetting() {
  yield takeLatest(actionTypes.UPDATE_BUNDLE_SETTINGS, sendBundleSettingsSaga);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * watchGetBalanceDetailo() {
  yield takeLatest(actionTypes.GET_BALANCE_DETAIL, getBalanceDetailSaga);
}

function * watchGetTransactionsChart() {
  yield takeLatest(actionTypes.GET_TRANSACTIONS_CHART, getTransactionsChartSaga);
}

function * watchFetchMoreTransactionsChartSaga() {
  yield takeLatest(actionTypes.FETCH_MORE_TRANSACTIONS_CHART, fetchMoreTransactionsChartSaga);
}

function * watchGetAuAndStockBalanceInfo() {
  yield takeLatest(actionTypes.GET_AU_AND_STOCK_BANLANCE_INFO, getAuAndStockBalanceInfoSaga);
}

export function * balanceSaga() {
  yield all([
    watchGetBalanceDetailo(),
    watchGetTransactionsChart(),
    watchFetchMoreTransactionsChartSaga(),
    watchGetAuAndStockBalanceInfo(),
    watchGetBundleInfo(),
    watchUpdateBundleSetting()
  ]);
}
export { handleGetBalanceDetailData, fetchMoreTransactionsChartSaga, getBalanceDetailSaga };
